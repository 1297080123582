<template>
    <div>        
        <div class="mt-0">        
            <div>
                <div>
                    <b-form @submit="onSubmit" v-if="show">
                    <b-form-group class="label" id="input-group-1" label="Key Action Name:" label-for="input-1" size="sm">
                        <b-form-input
                        id="input-1"
                        size="sm"
                        v-model="form.name"
                        placeholder="Enter Name"
                        required
                        ></b-form-input>
                    </b-form-group>

                    <div class="container">
                        <div class="row">
                            <div class="col-sm p-0">
                                <b-form-group id="input-group-2" label="Start Date:" label-for="input-2" size="sm" >
                                    <b-form-input
                                    id="input-2"
                                    size="sm"
                                    v-model="form.start_date"
                                    type="date"
                                    placeholder="Select Start Date"
                                    required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-sm p-0" style="margin-left: 4px;">
                                <b-form-group id="input-group-3" label="End Date:" label-for="input-3" size="sm" >
                                    <b-form-input
                                    id="input-3"
                                    size="sm"
                                    type="date"
                                    v-model="form.end_date"
                                    placeholder="Select End Date"
                                    required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <b-form-group id="input-group-4" label="Owner:" label-for="input-4" size="sm">
                        <vue-typeahead-bootstrap
                        ref="userSearch"
                        :data="users"
                        size="sm"
                        v-model="owner_query"
                        placeholder="Search name/email"
                        @hit="selectedOwner"
                        showOnFocus
                        prepend="Users:"
                    ></vue-typeahead-bootstrap>
                    </b-form-group>

                    <b-form-group id="input-group-5" label="Responsible User:" label-for="input-5" size="sm">
                        <vue-typeahead-bootstrap
                        ref="userSearch"
                        :data="users"
                        size="sm"
                        v-model="user_query"
                        placeholder="Search name/email"
                        @hit="selectedResponsibleUser"
                        showOnFocus
                        prepend="Users:"
                    ></vue-typeahead-bootstrap>
                    </b-form-group>
                    <b-form-group id="input-group-6" label="Notes:" label-for="input-5" size="sm">
                        <b-form-textarea v-model="form.notes" rows="3"></b-form-textarea>
                    </b-form-group>
                    <b-button type="submit" class='ms-auto ml-1' style="float: right" variant='primary' size="sm">Update Key Action</b-button>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods, ganttComputed, ganttMethods, performanceComputed } from '@/state/helpers'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { format, parseISO  } from 'date-fns';
import Swal from 'sweetalert2';
import dateMixin from '@/mixins/dateMixin.js'
export default {
components:{
   VueTypeaheadBootstrap,
},
mixins:[ dateMixin ],
props:{
    payload:{
        required: true,
    },
},
data:() => ({
    searchList: [],
    objectivesList: [],
    openObjectivesList: [],
    is_loading: false,
    is_loading_all_linkable_plans: false,
    linkable_plans_data: [],
    show_all_linkable_plans: false,
    change_objective_mode: false,
    users: [],
    filter_input: '',
    combined_targets_array: [],
    selectedPlainId: 0,
    combined_objectives_array: [],
    linked_objective_name: '',
    linked_key_result_name: '',
    new_objective: false,
    query: '',
    selectedId: '',
    selectedName: '',
    selectedType: '',
    owner_query: '',
    user_query: '',
    form: {
            id: -1,
            parent_id: 0,
            level_id: null,
            objective_id: null,
            target_id: null,
            key_result_id: null,
            name: '',
            start_date: '',
            end_date: '',
            type:  "plan",
            description: 'Empty',
            body: 'Empty',
            notes: '',
            status: '0',
            priority: 'low',
            owner_id: '',
            linked_project_ids: [],
            responsible_user_id: '',
    },
    show: true,

    parent_item_id: -1,
    parent_item_type: '',
    parent_name: '',
}),
watch:{
},
computed:{
    ...levelComputed,
    ...activityComputed,
    ...profileComputed,
    ...ganttComputed,
    ...performanceComputed,
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...levelMethods,
    ...ganttMethods,
    selectedOwner(item) {
        let email = item.split('|')[1];
        let email_string = email.trim();
        let user = this.all_users.find( item => {
            return item.email == email_string
        });
        this.form.owner_id = user.id
        this.form.responsible_user_id = user.id
    },
    selectedResponsibleUser(item) {
        let email = item.split('|')[1];
        let email_string = email.trim();
        let user = this.all_users.find( item => {
            return item.email == email_string
        });
        this.form.responsible_user_id = user.id
    },
    onSubmit(event) {
        event.preventDefault()
        this.form.start_date = format(parseISO(this.form.start_date), 'yyyy-MM-dd')
        this.form.end_date = format(parseISO(this.form.end_date), 'yyyy-MM-dd')
        let endCheck = this.endDateCheck(this.form.end_date, this.form.start_date)
        let startCheck = this.startDateCheck(this.form.start_date, this.form.end_date);
        if(!endCheck || !startCheck) {
            Swal.fire({
                title: "Invalid Dates",
                text: "Please order dates correctly",
                icon: "warning",
            })
        } else {
            this.$emit('saved', this.form)
        }
    },
    populateList(){
        let users = [];
        this.all_users.forEach( user =>{
            users.push(`${user.name} | ${user.email}`)
        });
        this.users = users;
    },
    processForm() {
        this.form.id = this.payload.id
        this.form.level_id = this.payload.level_id
        this.form.type = this.payload.type
        this.form.name = this.payload.name
        this.form.notes = this.payload.notes
        this.form.start_date = format(new Date(this.payload.start_date), 'yyyy-MM-dd')
        this.form.end_date = format(new Date(this.payload.end_date), 'yyyy-MM-dd')
        if(this.payload.milestone_objective_id) {
            this.form.objective_id = this.payload.milestone_objective_id
        }
        if(this.payload.target_id) {
            this.form.target_id = this.payload.target_id
        }
        if(this.payload.linked_project_ids) { 
            this.form.linked_project_ids = this.payload.linked_project_ids
        }
        //set owner
        let owner = this.all_users.find( item => {
            return item.id == this.payload.owner_user_id
        });
        this.form.owner_id = this.payload.owner_user_id
        this.owner_query = `${owner.name} | ${owner.email}`

        //set reponsible user
        let responsible = this.all_users.find( item => {
            return item.id == this.payload.responsible_user_id
        });
        this.form.responsible_user_id = this.payload.responsible_user_id
        this.user_query = `${responsible.name} | ${responsible.email}`
    }

},
beforeDestroy() {
    this.modal_payload = {}
},
mounted(){
    this.populateList();
    this.processForm()
    // this.loadPerformanceTargets(this.selected_level.id).then(()=>{
    //     this.reloadGoalObjectveData()
    // })
    //this.initModal();
    //this.populateList();
    //this.getObjectiveName();
},
}
</script>
<style>
#kanbanModal___BV_modal_header_ {
    background-color: #EBEDF0;
}
#input-group-1__BV_label_ {
    font-size: 11px;
}
#input-group-2__BV_label_ {
    font-size: 11px;
}
#input-group-3__BV_label_ {
    font-size: 11px;
}
#input-group-4__BV_label_ {
    font-size: 11px;
}
#input-group-5__BV_label_ {
    font-size: 11px;
}

.selected_style  {
    border: 2px black;
    color: #0275d8;
    font-weight: bold;
}

.levelTree {
    max-height: 300px
}
</style>