<template>
    <div style="height: 100%; width: 100%;" id="kanbanElement">
        <b-overlay :show="is_loading" v-bind:style="(is_loading == true && fullscreen_kanban == false) ? { 'min-height': kanbanHeight + 'px' }: ''">
        <b-card>
            <CardHeader id="kanbanCardHeader">
                <template #title>
                    Key Actions (Linked to Organisational Structure, Strategic Plans, or Operational Targets)
                    <span v-if="selected_goal_objective != -1 || selected_personal_objective != -1">
                        &nbsp;>&nbsp;
                    </span>
                    <span class="text-primary">{{ getName }}</span>
                </template>
                <template #buttons>
                    <div class="btn-group ms-1" size="sm">
                            <span class="ms-3 me-1 mt-1">
                            Child Key Actions:
                            </span>
                            <span v-show="viewChildren == 0"><span class="mt-1" title="Display Children Key Actions" ></span><font-awesome-icon @click="viewChildren = 1" class="fa fa-toggle-off fa-lg fa-2x" :icon="['fa', 'toggle-off']" style="color: #0275d8" /></span>
                            <span v-show="viewChildren == 1"><span class="mt-1" title="Hide Children Key Actions"></span><font-awesome-icon @click="viewChildren = 0" class="fa fa-toggle-on fa-lg fa-2x" :icon="['fa', 'toggle-on']" style="color: #0275d8" /></span>
                   </div>
                    <p size="sm" class="ms-2 me-1 mt-1">Order By:</p>
                    <b-dropdown variant="primary" size="sm" class="ms-1" no-caret>
                        <template #button-content>
                            <span class="me-2">{{ selectedFilter }}</span>
                            <font-awesome-icon icon="fa-caret-down"/>
                        </template>
                        <b-dropdown-item v-for="value in data_filter" :key="value.unit" @click="selectFilterValue(value.unit)">
                            {{value.unit}}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown variant="primary" size="sm" class="ms-1 me-1" no-caret>
                        <template #button-content>
                            <span class="me-2">{{ selectedFilterDirection }}</span>
                            <font-awesome-icon icon="fa-caret-down"/>
                        </template>
                        <b-dropdown-item v-for="value in data_filter_direction" :key="value.unit" @click="selectFilterDirectionValue(value.unit)">
                            {{value.unit}}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-button v-show="is_component_disabled == false && !fullscreen_kanban" size="sm" variant="primary" class="mr-2" @click="setShowKanban(false)">
                        <font-awesome-icon :icon="['fas', 'table']" />
                    </b-button>
                    <b-button v-show="is_component_disabled" title="Select an Objective before adding Action Plans" size="sm" variant="primary"  class="me-2" style="display: inline-block;" v-b-tooltip.hover.left >
                        <font-awesome-icon :icon="['fas', 'table']" />
                    </b-button>
                    <b-button title="Return to Performance screen" v-show="(selected_goal_objective != -1 || selected_personal_objective != -1) && fullscreen_kanban" size="sm" class="ms-1" @click="fullscreenKanban(false)" variant="primary">
                        <font-awesome-icon :icon="['fas', 'compress-arrows-alt']" />
                    </b-button>
                    <b-button title="FullScreen Kanban" v-show="(selected_goal_objective != -1 || selected_personal_objective != -1) && !fullscreen_kanban" size="sm" class="ms-1" @click="fullscreenKanban(true)" variant="primary">
                        <font-awesome-icon :icon="['fas', 'expand-arrows-alt']" />
                    </b-button>
                    
                    <b-button title="Add Key Action" size="sm" class="ms-1" @click="addActionPlan()" variant="primary">
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </b-button>
                    <b-button size="sm" class="ms-1" @click="showColumnPrompt(-1)" v-show="editMode" variant="primary">
                        <span>Add New Column</span>
                    </b-button>
                    <b-button v-if="false" size="sm" class="ms-1" @click="editMode= !editMode" variant="primary">
                        <span>Edit Columns</span>
                    </b-button>
                    <GuidelineModalButton class="ms-1" guideline_key="kanban"/>
                </template>
            </CardHeader> 
            <div>
                <div v-if="editMode">
                    <div class="webix_view webix_list webix_kanban_list">
                        <draggable v-model='ui.body.cols' class="d-flex justify-content-center ">
                            <div class="m-2 flex-grow-1 webix_accordionitem_header" style="background-color: white;" v-for="(element, index) in ui.body.cols" :key="element.status" role="tab" >
                                <font-awesome-icon @click="showColumnPrompt(index)" style="top: 8px;position: absolute;right: 8px;" icon="fa-pen"></font-awesome-icon>
                                <span class="webix_accordionitem_label">
                                    {{element.header}}
                                </span>

                                <div class="p-4">

                                </div>
                                    
                            </div>               
                        </draggable>
                    </div>
                    
                    <br>
                </div>
                <kanbanModal
                v-if="show_card_details_modal"
                :payload="modal_payload"
                @saved="populateKanban(true, true)"
                @savedNewDetails="handleRefresh"
                @scroll_to_gantt="scrollToItem()"
                />
                <addActionPlanModal
                v-if="show_action_plan_modal"
                :payload="modal_payload"
                @saved="populateKanban(true, true)"
                />
                <updateProjectsModal 
                v-if="show_projects_modal"
                :payload="modal_payload"
                :item="card_item"
                @savedNewDetails="populateKanban(true, true)"
                />
                <webix-ui :style="{'min-height': ui.height + 'px'}" v-if="show_chart && !editMode" style="width: 100%" :config="ui"/>
            </div>
        </b-card>
        </b-overlay>
    </div>
</template>
<script>
/* eslint-disable */
// eslint-disable-next-line
import { activityComputed, peopleComputed, peopleMethods, activityMethods, ganttMethods, ganttComputed, levelComputed, adminComputed, adminMethods, performanceComputed, performanceMethods, profileComputed, profileMethods, levelMethods, settingsComputed } from '@/state/helpers';
// eslint-disable-next-line
import { compareAsc, format, parseISO } from "date-fns";
import draggable from 'vuedraggable';
import kanbanModal from '@/components/kanban/modals/kanbanModel.vue';
import addActionPlanModal from '@/components/kanban/modals/addActionPlanModal.vue';
import _ from 'lodash';
// eslint-disable-next-line
import CardHeader from '@/components/widgets/cardHeader.vue';
import updateProjectsModal from './modals/updateProjectsModal.vue';
import GuidelineModalButton from '../widgets/misc/guidelineModalButton.vue';
export default {
    components: { CardHeader, draggable, kanbanModal, addActionPlanModal, updateProjectsModal, GuidelineModalButton },
    data:() => ({
        show_chart: false,
        plans_array: [],
        updated_kanban_items: [],
        window_resize_event: null,
        columns: [],
        modal_payload: null,
        children: [],
        show_card_details_modal: false,
        show_action_plan_modal: false,
        displayObjorKA: 0,
        selectedFilter: 'Priority',
        selectedFilterDirection: 'ASC',
        kanbanHeight: 700,
        displayObjorKA: 0,
        viewChildren: 0,
        show_projects_modal: false,
        card_item: null,
        hide_columns: false,
        is_loading: false,
        data_filter: [ { unit: "Priority" },
                { unit: "Deadline"},
                { unit: "Start Date"} ],
        data_filter_direction: [ { unit: "ASC"},
                { unit: "DESC"} ],
        employeesList: [],
        editMode: false,
        gantt_selected_objective: '',
        ui:{
            id: 'kanbanContainer',
            view:"scrollview", scroll:"x", css:'kanbanScrollContainer',
            //minHeight: 500,
            body:{
                css: "objectivesKanBan",
                id: "myKanBan",
                type: "space",
                view: "kanban",
                cols:[],
                data:[],
                // cardActions:[                               
                //     "View Details", "Parent Objective",
                // ],
                userList:true,
                on:{
                    "data->onStoreUpdated": function(){
                        let el = this.$scope.$parent.$parent;
                        setTimeout(() => {
                            el.doColumnCounts();
                        }, 200)
                    },
                    onListBeforeDragIn(context, e, list){
                        let el = window.kanbanContext;
                        el.customAutoScroll.call(this);
                    },
                    onListDragOut(){
                        let el = window.kanbanContext;
                        el.customAutoScroll.call(this);
                    },
                    onListBeforeDragIn:function(dragContext,e,list) {
                        let el = window.kanbanContext;
                        var item = this.getItem(dragContext.start);
                        if(el.$hasPerm('action-plans-manager') || el.$hasPerm('action-plans')){
                            if(!el.isOwnerOrResponsibleUserOfItem(item)){
                                el.$swal.fire({
                                    title: "Only the owner or responsible users of this card or linked projects can move it",
                                    icon: "warning",
                                    confirmButtonColor: "#34c38f",
                                    confirmButtonText: "Ok",
                                })
                            }
                        } else {
                            el.$swal.fire({
                                title: "Action Plan permission required to move cards",
                                icon: "warning",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "Ok",
                            }) 
                        }
                        
                        
                        
                    },
                    onListAfterDrop:function(dragContext,e,list) {
                        let el = window.kanbanContext
                        var item = this.getItem(dragContext.start);
                        // if we move an item from one list to another
                        if(dragContext.from != dragContext.to){
                            let params = {
                                item_id: item.id,
                                item_type: 'action_plan',
                                dest_list_id: dragContext.to.config.column_id,
                            }
                            window.kanbanContext.moveKanbanListItem(params).then(() => {
                                el.$nextTick(() => {
                                    let params1 = {
                                    item_id: el.selected_level.id,
                                    item_type: 'level',
                                    user_id: el.selected_user_id, 
                                    }
                                    window.kanbanContext.getClientProjects(params1).then(() => {
                                        var drop_item = el.kanban_data.find( value => { return value.id == item.id });
                                        //let project_ids = []
                                        var item1 = this.getItem(item.id);
                                        // item1['status'] = item1.status;
                                        this.updateItem(item.id, item1);
                                        let linked_projects = el.findLinkedProjects(drop_item)
                                        if(linked_projects.length > 0 && (Number(dragContext.to.config.column_id) == Number(el.loaded_kanban_config.lists.length))) {
                                            el.$swal.fire({
                                                title: "Projects Linked",
                                                text: "Would you like to unlink projects from this Key Action?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#34c38f",
                                                cancelButtonColor: "#f46a6a",
                                                confirmButtonText: "Yes",
                                            }).then( result =>{
                                                if(result.value){
                                                    el.modal_payload = linked_projects
                                                    el.card_item = drop_item
                                                    el.show_projects_modal = true;
                                                    el.$nextTick(()=>{
                                                        el.$bvModal.show('updateProjectsModal');
                                                        el.$root.$once("bv::modal::hidden", (event) => {
                                                            if (event.type == "hidden" && event.componentId == "updateProjectsModal") {
                                                                el.show_projects_modal = false;
                                                                el.modal_payload = null;
                                                            }
                                                        });
                                                    })
                                                } 
                                            })  
                                        }
                                    })
                                })
                               
                            })
                            //.then(()=>{
                                //window.kanbanContext.populateKanban(false, false, false);
                            //})
                        }
                    },
                    onListItemClick:function(id,ev,node,list){
                        
                        let el = window.kanbanContext;
                         if(ev.target.id == 'bottomName'){
                            ev.target.focus()
                            //eslint-disable-next-line
                            let table = window.webix.$$('myGanttTree');
                            let item =  el.kanban_data.find( key_action => {
                                return key_action.id == id
                            });
                            // if item not found, stop here
                            if(item == undefined){
                                return;
                            }
                            let parent_id = 0
                            if(item.milestone_objective_id == null) {
                                parent_id = item.milestone_objective_id;
                            } else {
                                parent_id = item.key_result_id;
                            }
                            
                            //* check if parent is milestone or key result
                            if(item.key_result_id != null){
                                parent_id = `kr-${item.key_result_id}`;
                            }
                            else if(item.milestone_objective_id != null){
                                parent_id = `objective-${item.milestone_objective_id}`;
                            }
                            else{
                                return;
                            }
                            
                            //now we have found the item, we find its top most parent
                            
                            item = table.getItem(parent_id);
                            if(item.$parent != 0) {
                                while(item.$parent != 0){
                                table.open(item.$parent);
                                item = table.getItem(item.$parent);
                                table.showItem(parent_id);
                                table.select(parent_id);
                                }
                            } else {
                                table.showItem(parent_id);
                                table.select(parent_id);
                            }
                        } else if (ev.target.classList[1] !== 'webix_kanban_icon'){
                            el.modal_payload = {}
                            let value = el.kanban_data.find( action => {
                                return action.id == id
                            }); 
                            let item = value     
                            var item1 = this.getItem(item.id);      
                            let status = item1.status    
                            item['status_value'] = status  
                            // let parent_objective_name = null;
                            // let key_result_name = null;
                            let level = el.levels.find( level => {
                                return level.id == item.level_id
                            });
                            let type = null
                            if(item.milestone_objective_id) {
                                type = 'objective'
                                parent = el.gantt_tasks.find( task => {
                                   return task.plain_id == item.milestone_objective_id
                                });
                                item['searchable'] = true
                                if(!parent) {
                                    parent = el.objective_details_data.objectives.find( task => {
                                    return task.id == item.milestone_objective_id
                                    });
                                    item['searchable'] = false
                                    if(!parent) {
                                        parent = el.parent_objectives_list.find( task => {
                                        return task.id == item.milestone_objective_id
                                        });
                                        item['searchable'] = false
                                    }
                                }
                            } else if(item.target_id != null) {
                                type = 'target'
                                parent = el.level_targets.find( target => {
                                    return target.id == item.target_id
                                });
                            } else {
                                type = 'level'
                                if(level) {
                                    parent = level.name
                                } else {
                                    parent = null
                                }
                                
                            } 
                            let user = el.levelEmployeesData.find( user => {
                                return user.id == item.owner_user_id;
                            });
                            
                           
                            let startDate = parseISO(item.start_date);
                            let endDate = parseISO(item.end_date);
                            if(user != undefined) {
                                item['src'] = user.avatar_src
                            }
                            item['quarter'] = 'Q' + Math.floor((startDate.getMonth() + 3) / 3);
                            item['year'] = format(startDate, 'Y')
                            item['formatted_start_date'] = format(startDate, 'dd/MM/yyyy');
                            item['formatted_end_date'] = format(endDate, 'dd/MM/yyyy');
                            if(level) {
                                item['structure'] = level.name 
                            } else {
                                item['structure'] = null
                            }
                            if(!parent) {
                                item['parent'] = 'No Linked Parent'
                            } else {
                                item['parent'] = parent
                            }
                            
                            item['link_type'] = type
                                                
                            el.modal_payload = item
                            el.show_card_details_modal = true;
                            el.$nextTick(()=>{
                                el.$bvModal.show('kanbanModal');
                                el.$root.$once("bv::modal::hidden", (event) => {
                                    if (event.type == "hidden" && event.componentId == "kanbanModal") {
                                        el.show_card_details_modal = false;
                                        el.modal_payload = null;
                                    }
                                });
                            })
                        }    
                    },
                },     
            },
        }
    }),
    computed:{
        ...activityComputed,
        ...ganttComputed,
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        ...performanceComputed,
        ...profileComputed,
        ...settingsComputed,
        is_component_disabled(){
            // returns true if objective id is selected
            return this.selected_goal_objective == -1;
        },
        getData() {
            let unfilteredData = _.cloneDeep(this.kanban_data);
            let data = unfilteredData
            if(this.selected_user_id == null) {
                data = unfilteredData.filter((ele) => ele.type != 'personal')
            }

            let mainData = []
            data.map( item => {
                if(this.selected_user_id != null) {
                    if(this.selected_user_id == item.owner_user_id || this.select_user_id == item.responsible_user_id){
                    mainData.push(item)
                    }
                } else {
                    mainData.push(item)
                }
                
            })
            data = mainData.map( item => {
                    return this.returnCards(item)              
            })

            
            // data.sort("#priority#","asc","string");
            let value = this.selectedFilterDirection.toLowerCase();
            if(this.selectedFilter == 'Start Date') {
                let main_data =  _.orderBy(data, function(num){
                    let start_date = num.tags.split('-')[0]
                    let date = new Date(start_date);
                    return date
                },value);
                return main_data
            } else if (this.selectedFilter == 'Deadline') {
                let main_data =  _.orderBy(data, function(num){
                    let end_date = num.tags.split('-')[1]
                    let date = new Date(end_date);
                    return date
                },value);
                return main_data
            } else {
                let main_data =  _.orderBy(data, function(num){
                    var rank = {
                        "high": 1,
                        "medium": 2,
                        "low":3,
                    };
                    return rank[num.priority]
                },value);
                return main_data
            }     
        },
        getName() {            
            if(this.gantt_selected_objective_id !== -1 && this.gantt_tasks && this.show_gantt) {
                let item = this.gantt_tasks.find( task => {
                    let task_type = 'objective'
                    if(task.item_type == 'key_result'){
                        task_type = 'kr'
                    }
                    if(task.plain_id == this.gantt_selected_objective_id && this.gantt_selected_item_type == task_type) {
                        return task.plain_id == this.gantt_selected_objective_id
                    }    
                });
                if(!item){
                    return ''
                }
                  return item.text
            } else {
                    if((this.selected_goal_objective != -1 || this.selected_personal_objective != -1) && this.current_objective_details) {  
                    return this.current_objective_details.name
                    } else {
                        return null
                    }
            }
            // if(this.parent_item_id !== 0) {
            // let parent_name = this.gantt_tasks.find( task => {
            //         let task_type = 'objective'
            //         if(task.item_type == 'key_result'){
            //             task_type = 'kr'
            //         }
            //         if(task.plain_id == this.parent_item_id && this.parent_item_type == task_type) {
            //             return task.plain_id == this.parent_item_id
            //         }    
            // });
            // if(parent_name.id.includes('kr')){
            //     this.linked_key_result_name = parent_name.text
            // } else {
            //     this.linked_objective_name = parent_name.text
            // }
            
        },
        fetchGanttData: _.debounce(function(should_clear = false){
            let params = {
                milestone_id: this.pinned_milestone_id,
                level_id: this.selected_level.id,
                user_id: this.selected_user_id != null ? this.selected_user_id : -1
            };
            this.loadGanttData(params)
            .then(()=>{
                if(this.selected_objective == null){
                    return;
                }
            })
        },50),
    },
    methods:{
        ...activityMethods,
        ...ganttMethods,
        ...peopleMethods,
        ...adminMethods,
        ...profileMethods,
        ...levelMethods,
        ...performanceMethods,
        findLinkedProjects(item) {
            let projectsList = []
            this.projects.forEach(project => {
                if(project.objective_plans.length != 0) {
                    let plans = project.objective_plans
                    plans.forEach(plan => {
                        if(plan.id == item.id) {
                            projectsList.push(project);
                        }
                    })
                }
            })
            return projectsList
        },
        isOwnerOrResponsibleUserOfItem(item) {
            let responsible_user = false
            if(item.responsible_user_id == this.core.id) {
                responsible_user = true
            }
            let linked_projects = this.findLinkedProjects(item)
            let isLinkedToProject = false
            this.projects.forEach(data => {
                linked_projects.forEach(project => {
                    if(project.id == data.id) {
                        if(data.owner_user_id == this.core.id) {
                            isLinkedToProject = true
                        }
                        data.responsible_users.forEach(user => {
                            if(user.id == this.core.id) {
                                isLinkedToProject = true
                            }
                        })
                    }
                })
            
            })
            let is_owner = Number(this.core.id) == Number(item.user_id);
            return is_owner || responsible_user || isLinkedToProject;
        },
        handleRefresh(id) {
            this.populateKanban(true, true)
            setTimeout(() => {
                this.modal_payload = {}
                let item = this.kanban_data.find( action => {
                    return action.id == id
                });                           
                let level = this.levels.find( level => {
                    return level.id == item.level_id
                });
                let type = null
                if(item.milestone_objective_id) {
                    type = 'objective'
                    parent = this.gantt_tasks.find( task => {
                        return task.plain_id == item.milestone_objective_id
                    });
                    item['searchable'] = true
                    if(!parent) {
                        parent = this.objective_details_data.objectives.find( task => {
                        return task.id == item.milestone_objective_id
                        });
                        item['searchable'] = false
                        if(!parent) {
                            parent = this.parent_objectives_list.find( task => {
                            return task.id == item.milestone_objective_id
                            });
                            item['searchable'] = false
                        }
                    }
                } else if(item.target_id != null) {
                    type = 'target'
                    parent = this.level_targets.find( target => {
                        return target.id == item.target_id
                });
                } else {
                    type = 'level'
                    if(level) {
                        parent = level.name
                    } else {
                        parent = null
                    }
                    
                } 
                let user = this.levelEmployeesData.find( user => {
                    return user.id == item.owner_user_id;
                });
                let startDate = parseISO(item.start_date);
                let endDate = parseISO(item.end_date);
                if(user != undefined) {
                    item['src'] = user.avatar_src
                }
                item['quarter'] = 'Q' + Math.floor((startDate.getMonth() + 3) / 3);
                item['year'] = format(startDate, 'Y')
                item['formatted_start_date'] = format(startDate, 'dd/MM/yyyy');
                item['formatted_end_date'] = format(endDate, 'dd/MM/yyyy');
                if(level) {
                    item['structure'] = level.name 
                } else {
                    item['structure'] = null
                }
                if(!parent) {
                    item['parent'] = 'No Linked Parent'
                } else {
                    item['parent'] = parent
                }
                item['link_type'] = type
                
                this.modal_payload = item

            }, 1000)
                
        },
        reloadGoalObjectveData(){
            return new Promise( (resolve, reject) => {
                let params = {
                    level_id: this.current_level_id,
                    milestone_data: 1,
                }
                this.loadPerformanceScreenData({
                    type: 'objective_details',
                    params: params,
                }).then(()=>{
                    resolve();
                    this.is_loading = false
                })
                .catch(()=>{
                    reject();
                })
            })
        },
        setColor(value) {
            switch(value) {
                case 'low':
                    return 'badge-item-low';
                case 'medium':
                    return 'badge-item-medium';
                case 'high':
                    return 'badge-item-high';
                default:
                    return 'badge-item-low';
            }
        },
        doColumnCounts: _.debounce(function(){
            let count = {};
            this.ui.body.data.forEach( item => {
                if(!count[item.status]){
                    count[item.status] = 0;
                }
                count[item.status]++;
            })
            this.ui.body.cols.forEach( col => {
                let view = window.webix.$$(col.id);
                if(!count[col.id]){
                    count[col.id] = 0;
                }
                view.define("header", view.config.headerText+" ("+count[col.id]+")");
                view.refresh();
            })
            
        }, 1000),
        addActionPlan() {         
            this.show_action_plan_modal = true;
            let index = _.findIndex(this.levels, {id: this.selected_level.id});
            let name = ''
            if( index != -1){
                name = this.levels[index].name;
            }
            this.modal_payload = { level_id: this.selected_level.id, level_name: name};
            if(this.$hasPerm('action-plans') || this.$hasPerm('action-plans-manager')){
            this.$nextTick(()=>{
                this.$bvModal.show('addActionPlanModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addActionPlanModal") {
                        this.show_action_plan_modal = false;
                        this.modal_payload = null;
                    }
                });
            })    
            } else {
                this.$swal.fire({
                    title: "Action Plan permission required to add Key Action",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }) 
            }
        },
        selectFilterValue(value) {
            this.selectedFilter = value;
            this.populateKanban(true, true, false);
            
        },
        selectFilterDirectionValue(value) {
            this.selectedFilterDirection = value;
            this.populateKanban(true, true, false);
        },
        isDisable() {
          return this.column_name == 0;
        },
        customAutoScroll(){
            const scrollView = window.webix.$$("kanbanContainer");
            const kanbanInstance = scrollView.queryView("myKanBan");
            const context = webix.DragControl.getContext();
            const node = webix.DragControl.getNode();
            if (node) {
                const nodePos = webix.html.offset(node);
                const viewPos = webix.html.offset(scrollView.$view);
                const viewPosX = viewPos.x;

                const rightNodePos = nodePos.x + nodePos.width;
                const rightViewPos = viewPos.x + viewPos.width;
                const contentWidth = scrollView.getBody().$width;
                const scrollPosX = scrollView.getScrollState().x;

                if (this._scrollDelay) {
                clearTimeout(this._scrollDelay);
                }

                const scrollDistance = 28; // Adjust the scroll distance as needed

                if ((rightNodePos > rightViewPos) && (viewPos.width + scrollPosX < contentWidth)) {
                scrollView.scrollTo(scrollPosX + scrollDistance);
                this._scrollDelay = setTimeout(() => this.customAutoScroll(), 20); // Reduce the delay between calls
                } else if ((nodePos.x < viewPos.x) && (scrollPosX > 0)) {
                scrollView.scrollTo(scrollPosX > scrollDistance ? scrollPosX - scrollDistance : 0);
                this._scrollDelay = setTimeout(() => this.customAutoScroll(), 20); // Reduce the delay between calls
                }
            }
        },
        setCardColor(attribute_id){
            let elem = document.querySelector(`[webix_l_id="${attribute_id}"]`);
            if(elem){
                elem.firstElementChild.classList.add('child')
                
            } 
        },
        //webix refresh() does not clear the added css which is why this was added
        clearCardColor(attribute_id){
            let elem = document.querySelector(`[webix_l_id="${attribute_id}"]`);
            if(elem){
                elem.firstElementChild.classList.remove('child')   
            } 
        },
        addColumn() {
           let checking = false
            this.ui.body.cols.forEach(col => {
                if(col.header.toLowerCase() == column_name.toLowerCase()) {
                    return checking = true
                }
            })
            if (!checking) {
                let cols_array = this.ui.body.cols
                cols_array.push({ header: column_name, body:{ view:"kanbanlist", status:'' }})
            }
        },
        
        returnCards (item) {
            let parent_name = {};
            //let parent_key_result_name = {};
            let type = '';
            if(item.milestone_objective_id == null && item.target_id == null){
                type = 'level'
                let level = this.levels.find( level => {
                    return level.id == item.level_id
                });
                parent_name = level.name 
                
            }
            else if(item.milestone_objective_id != null && item.target_id == null) {
                // type = 'objective'
                // let value = null
                // parent = this.goal_objectives_tree.forEach( task => {
                //     if(task.plain_id == item.milestone_objective_id) {
                //         value = task
                //     }
                // });
                // if(value != null) {
                //     parent_name = value.name
                // } else {
                //     parent_name = 'Parent Not Found'
                // }
                type = 'objective'
                parent = this.gantt_tasks.find( task => {
                    return task.plain_id == item.milestone_objective_id
                });
                if(!parent) {
                    parent = this.objective_details_data.objectives.find( task => {
                    return task.id == item.milestone_objective_id
                    });
                    if(!parent) {
                        parent = this.objective_details_data.parent_objectives.find( task => {
                        return task.id == item.milestone_objective_id
                        });
                    }
                }
                if(parent != null) {
                    parent_name = parent.text
                } else {
                    parent_name = 'Parent Not Found'
                }
            } else {
               type = 'target'
               let parent = this.level_targets.find( target => {
                    return target.id == item.target_id
                });
                if(parent != null) {
                    parent_name = parent.name
                } else {
                    parent_name = 'Parent Not Found'
                }
            }

            let value = {}
            let combinedDates = ''
            if(item.start_date && item.end_date) {
                let startDate = parseISO(item.start_date);
                let endDate = parseISO(item.end_date);
                combinedDates = format(startDate, 'dd MMM yy') + ` - ` + format(endDate, 'dd MMM yy')
            }
           
            value['id'] = item.id
            value['user_id'] = item.owner_user_id
            value['responsible_user_id'] = item.responsible_user_id
            value['tags'] = combinedDates,
            value['priority'] = item.priority,
            value['name'] = item.owner_user_name
            //value['icons'] = `<b-button class="webix_kanban_footer_icon">Test</b-button`
            value['status'] = item.status
            value['type'] = type;
            if(parent_name) {
                value['parent'] = parent_name
            } else {
                value['parent'] = ""
            }
            
            let val = this.levelEmployeesData.find((elem) => elem.id == item.owner_user_id);
            if(val) {
                value['name'] = val.name
                value['src'] = val.avatar_src
            } else {
                value['name'] = 'No owner'
                value['src'] = '/img/user.png'
            }

            value['text'] = item.type == 'personal' ? `
                <div class="d-flex" id="topName" style="font-size: 0.75rem">
                    <span class="badge-item badge-item-key-action-personal"></span>
                    <span>${item.name}</span>
                </div>
                ` : `
                <div class="d-flex" id="topName" style="font-size: 0.75rem">
                    <span class="badge-item badge-item-key-action"></span>
                    <span>${item.name}</span>
                </div>
                `;
            

            // was my attempt at cleanup
            /*
            let item_name_element =`
                <div>
                    <b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: rgb(107, 168, 203) !important" class="bg-primary bg-soft text-light">
                        &nbsp;KA 
                    </b-badge>&nbsp;
                    <span class="cursor-pointer" style="font-size: 12px;">${item.name}</span>
                </div>`;
            let parent_name_element = `
                <div>
                    <b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: #38aea2 !important" class="bg-primary bg-soft text-light">
                        &nbsp;OB 
                    </b-badge>&nbsp;
                    <span style="font-size: 11px">${parent_objective_name.text}</span>
                </div>`

            value['text'] = item_name_element + parent_name_element;
            */
            return value;
        },
        processEmployeeData() {
           let users_set = []
           this.levelEmployeesData.forEach(user => {
              let person = {}
              person['id'] = user.id
              person['value'] = user.name
              person['image'] = user.avatar_src
              users_set.push(person)
           })

           this.employeesList = users_set;
           this.ui.body.users = users_set;
           //this.loadGanttAndKanban(true)
           this.show_chart = true;
        },

        populateKanban(should_scroll_into_view, once, newSelection){
            this.is_loading = true;
            // this.is_loading = true
            //this.show_chart = false
            //this.show_chart = false;
            if(once) {
                this.show_chart = false;
                this.is_loading = true
            }
            if(newSelection) {
                this.show_chart = false;
                this.is_loading = true
                this.ui.height = 0
            }
           
            
            
            let params = null
            if(this.gantt_selected_item_type == 'kr') {
                params = {
                "kanban_id": "-1",
                "item_id": this.gantt_selected_objective_id,
                "item_type": this.gantt_selected_item_type,
                "fetch_children": this.viewChildren,
                "start_date": this.kanban_start_date,
                "end_date": this.kanban_end_date
                }
            } else if(this.gantt_selected_item_type == 'objective') {
                if(this.selected_goal_objective != -1) {
                    params = {
                        "kanban_id": "-1",
                        "item_id": this.selected_goal_objective,
                        "item_type": 'objective',
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                }
                if(this.selected_personal_objective != -1) {
                    params = {
                        "kanban_id": "-1",
                        "item_id": this.selected_personal_objective,
                        "item_type": 'objective',
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                }
               
            }

            if(params == null){
                if(this.selected_kr_child_id != -1){
                    params = {
                        "kanban_id": "-1",
                        "item_type": "key_result",
                        "item_id": this.selected_kr_child_id,
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                }
                else if(this.selected_kr_id != -1){
                    params = {
                        "kanban_id": "-1",
                        "item_type": "key_result",
                        "item_id": this.selected_kr_id,
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                }
                else if(this.selected_goal_objective != -1){
                    params = {
                        "kanban_id": "-1",
                        "item_type": "objective",
                        "item_id": this.selected_goal_objective,
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                } else if(this.selected_personal_objective != -1){
                    params = {
                        "kanban_id": "-1",
                        "item_type": "objective",
                        "item_id": this.selected_personal_objective,
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                }
                else if(this.selected_project_id != -1){
                    params = {
                        "kanban_id": "-1",
                        "item_type": "project",
                        "item_id": this.selected_project_id,
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                }
                // else if(this.pinned_milestone_id != -1) {
                //     params = {
                //         "kanban_id": "-1",
                //         "item_type": "milestone",
                //         "item_id": this.pinned_milestone_id,
                //         "fetch_children": this.viewChildren
                //     }
                // }
                else{
                    params = {
                        "kanban_id": "-1",
                        "item_type": "level",
                        "item_id": this.selected_level.id,
                        "fetch_children": this.viewChildren,
                        "start_date": this.kanban_start_date,
                        "end_date": this.kanban_end_date
                    }
                }
                
            }
           
            if(once) {
                this.loadGanttKanbanData(params)
                .then(()=>{
                    this.processKanbanInfo(should_scroll_into_view, once, newSelection)
                });
            } else {
                this.processKanbanInfo(should_scroll_into_view, once, newSelection)
            }
            
        },
        processKanbanInfo(should_scroll_into_view, once, newSelection) {
            if(this.loaded_kanban_config == null){
                //* stop here if no kanban data exists
                return;
            }
            let kanban_columns = this.loaded_kanban_config.lists.map( column => {
                let value = column.percentile_value
                if(column.name == 'Delayed') {
                    value = -1
                }
                if(column.name == 'Archived') {
                   value = -2
                }
                return {
                    header: column.name,
                    body: {
                        view: "kanbanlist",
                        status: value,
                        column_id: column.id,
                        minWidth: 280,
                        type:"space"
                    },
                    order: column.order,
                    id: column.order,
                    headerText: column.name,
                }
                
            })
            kanban_columns = _.orderBy(kanban_columns, ['order']);      
            let el = this     
            webix.type(webix.ui.kanbanlist,{
                    
                    name: "space",
                    templateTags: function(obj) {
                        if(obj) {
                            let badge = el.setColor(obj.priority)
                            if(obj.type == 'objective') {
                                return '<div class="webix_kanban_tags"><span style="margin-top: 2px" class="badge-item badge-item-objective cursor-pointer" title="'+obj.parent+'"></span><span class="webix_kanban_tag">'+obj.tags+'</span><span style="margin-top: 2px; margin-left: 2px" class="badge-item '+badge+' cursor-pointer" title="'+obj.priority+'"></span></div>'
                            } else if(obj.type == 'target') {
                                return '<div class="webix_kanban_tags"><span style="margin-top: 2px" class="badge-item badge-item-target cursor-pointer" title="'+obj.parent+'"></span><span class="webix_kanban_tag">'+obj.tags+'</span><span style="margin-top: 2px; margin-left: 2px" class="badge-item '+badge+' cursor-pointer" title="'+obj.priority+'"></span></div>'
                            } else {
                                return '<div class="webix_kanban_tags"><span style="margin-top: 2px" class="badge-item badge-item-level cursor-pointer" title="'+obj.parent+'"></span><span class="webix_kanban_tag">'+obj.tags+'</span><span style="margin-top: 2px; margin-left: 2px" class="badge-item '+badge+' cursor-pointer" title="'+obj.priority+'"></span></div>'
                            }
                            
                        }
                    },
                    templateIcons: function(obj){
                        if(obj){
                            return '<div style="display: flex"><img style="height: 2rem; width: 2rem" class="webix_icon webix_kanban_icon kbi-account rounded-circle profile-img cursor-pointer" src="'+obj.src+'" title="'+obj.name+'"></img></div>'
                        }
                    },
            });
            
            if(this.fullscreen_kanban) {
                let value = document.getElementsByClassName('kanban');
                let height = window.innerHeight
                value[0].style.height = height + 'px'
                this.ui.maxHeight = 2000
                this.ui.height = window.innerHeight - 148;
                this.kanbanHeight = window.innerHeight - 148;
                window.kanbanContext = this;
            } else {
                let value = document.getElementsByClassName('page-content');
                value[0].style.removeProperty('height')
                this.ui.minHeight = 400
                this.ui.maxHeight = 650
                this.kanbanHeight = 650
            }
                this.ui.body.cols = kanban_columns;
                this.ui.body.data = this.getData;          
            if(once || newSelection) {
                if(should_scroll_into_view) {
                    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
                }
                this.show_chart = true
            }

            if(!once && !newSelection) {
                this.is_loading = true
                this.show_chart = false
                //this.resizeChart();
            }  
            this.$nextTick(()=>{
                if(!once && !newSelection) {
                    this.show_chart = true
                    
                }
                this.is_loading = false;
            })
        },
        resizeChart() {
            setTimeout(() => {
                let mainHeight = 0
                this.ui.body.cols.forEach( col => {
                    let view = window.webix.$$(col.id);
                
                    if((Number(view.$height) > Number(mainHeight))) {
                        mainHeight = view.$height
                    }
                })
                if(mainHeight < 300) {
                    this.ui.height = 450
                } else if(mainHeight > 700) {
                    this.ui.height = 700
                } else {
                    this.ui.height = mainHeight + 60
                }
                this.show_chart = false
                this.populateKanban(false, false, false)
            }, 100)
        },
        // scrollToTopOfElement(elementId, headerHeight, padding) {
        //     var element = document.getElementById(elementId);
        //     var topOffset = element.getBoundingClientRect().top;
        //     var scrollTop = topOffset - headerHeight - padding;
        //     window.scrollTo({
        //         top: scrollTop,
        //         behavior: 'smooth'
        //     });
        // },
        scrollToItem() {
            this.$nextTick(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
        }
    },
    watch:{
        refresh_kanban() {
            this.populateKanban(false, true);
        },
        current_level_id(){
            this.loadPerformanceTargets(this.selected_level.id).then(()=>{
                this.reloadGoalObjectveData();
                this.$nextTick(() => {
                    this.populateKanban(false, true);
                })
            })
        },
        selected_kr_id(newVal){
            if(newVal != -1){
                this.populateKanban(false, true);
            }
            else if(this.objective_details_data != null){
                this.populateKanban(false, true);
            }
        },
        selected_kr_child_id(newVal){
            if(newVal != -1){
                this.populateKanban(false, true);
            }
            else{
                if(this.selected_kr_id != -1){
                    this.populateKanban(false, true);
                }
            }
        },
        viewChildren() {
            this.populateKanban(true, true, false);
        },
        objective_details_data(newVal){
            if(newVal != null){
                this.populateKanban(false, false, true);
            }
            else{
                this.populateKanban(false, true);
            }
        },
        selectedMilestoneGoal(newValue){
            if(newValue != null){
                this.populateKanban(false, true);
            }
        },
        selected_project_id(newValue){
            if(newValue != null){
                this.populateKanban(false, true, true);
            }
        },
        projects(newValue, oldValue){
            if(oldValue.length != 0 & newValue.length != 0) {
                if(newValue[0].objective_plans.length != oldValue[0].objective_plans.length && newValue != null && this.selected_project_id != null){
                    this.populateKanban(false, true);
                }
            }
        },
        // pinned_milestone_id(newValue){
        //     if(newValue != null){
        //         this.populateKanban(false, false, true);
        //     }
        // },
        gantt_selected_objective_id() {
            //* load kanban data for gantt
            if(this.selected_goal_objective == -1){
                return;
            }
            if(this.gantt_selected_item_type == 'kr') {
                this.populateKanban(false, true, true);
            }
        },
        selected_user_id() {
            if(this.selected_user_id == null && this.selected_personal_objective != -1) {
                this.selectPersonalObjective(-1).then(()=> {
                    this.populateKanban(false, true, true);
                })
            } else {
                this.populateKanban(false, true, true);
            }
        },
        show_gantt() {
            if(this.show_kanban) {
                this.populateKanban(false);
            } else {
                this.populateKanban(false);
            }
        },
        kanban_start_date() {
            if(this.kanban_start_date != undefined) {
                this.populateKanban(false, true, true);
            }
        },
        is_left_menu_collapsed() {
            let container = window.webix.$$('kanbanContainer');
            if(container){
                container.adjust();
            }
            let table = window.webix.$$('myKanBan');
            if(table){
                table.adjust();
            }
        },
        selected_goal_objective() {
            this.populateKanban(false, true)
        },
        selected_personal_objective() {
            this.populateKanban(false, true)
        }
    },
    beforeDestroy(){
        window.webix.eventRemove(this.webix_resize_event);
        window.kanbanContext = undefined;
        this.clearPinnedGanttItems();
        //this.setShowKanban(false)
    },
    mounted(){
        window.kanbanContext = this;
        this.show_chart = false;
        let params = {
            milestone_id: this.pinned_milestone_id,
            level_id: this.selected_level.id,
            user_id: this.selected_user_id != null ? Number(this.selected_user_id) : -1
        };
        this.loadGanttData(params)
        .then(()=>{
            if(this.selected_objective == null){
                return;
            }
        })
        this.loadPerformanceTargets(this.selected_level.id).then(()=>{
            this.reloadGoalObjectveData()
        })
        //this.showColumnConfig(this.ui.body.cols);
        if(this.levelEmployeesData.length == 0) {
            this.loadEmployeesData(this.company_id).then(() => {
            this.processEmployeeData();
            this.populateKanban(false, true);
            })
        } else {
            this.processEmployeeData();
            this.populateKanban(false, true);
        }
        
        let el = this;
        this.webix_resize_event = window.webix.event(window, "resize", _.debounce(function(){
            let container = window.webix.$$('kanbanContainer');
            if(container){
                container.adjust();
            }
            let table = window.webix.$$('myKanBan');
            if(table){
                table.adjust();
            }
            if(el.fullscreen_kanban) {
                el.populateKanban(false)
            }
        }, 100)); 

        // let allColumns = window.webix.$$('myKanBan').getColumns();
        
    }
}
</script>

<style>
#kanbanCardHeader {
    height: 24px !important;
    padding-bottom: 1px;
}
.webix_kanban_tag{
    font-size: 9px;
}
.objectivesKanBan .webix_gantt_link{
    display: none !important;
}
.objectivesKanBan .webix_gantt_task{
    cursor: pointer !important;
}

.objectivesKanBan .webix_view, .webix_input_icon {
    box-sizing: border-box !important;
}

.objectivesKanBan .webix_accordionitem_header {
    font-size: 12px !important;
    margin-right:1px;
    border-top: 1px solid #DADEE0;
}

.objectivesKanBan .webix_kanban_list {
    margin-right: 7px;
    padding-right: 7px;
}

.objectivesKanBan .webix_accordionitem {
    border-right: 1px dashed #3a7ada;
    padding-right: 5px;
    border-left-color: #EBEDF0;
    border-bottom: #EBEDF0;
    background: #EBEDF0;
    border-width: 0 !important;
    border-right-width: 1px !important;
    border-radius: 0;
}

.objectivesKanBan .webix_view, .webix_input_icon {
    box-sizing: border-box !important;
}

.scaleDropdown {
    margin: 0 auto;
}
.kanbanScrollContainer{
    background-color: #EBEDF0;
};
/* .objectivesKanban .webix_gantt_content { */
    /* line-height: 22px !important; */
    /* font-weight: bold; */
/* } */
.objectivesKanBan .webix_gantt_progress_drag {
    display: none !important;
}
.objectivesKanBan .webix_list_item{
    cursor: unset;
}
.objectivesKanBan .webix_kanban_list_content{
    cursor: grab !important;
}
.child {
    background-color: #EBEDF0;
}
.objectivesKanBan .webix_kanban_list_content #bottomName:hover {
    text-decoration: underline;
}
.objectivesKanBan .webix_kanban_body{
    min-height: 40px;
    padding-top: 7px;
    padding-left: 6px;
    padding-bottom: 4px;
    padding-right: 5px;
}
.objectivesKanBan .kanban {
    padding-bottom: 0px;
}

.objectivesKanBan .webix_kanban_tags {
    display: flex
}

.objectivesKanBan .webix_kanban_tag {
    display: flex !important;
    align-items: center !important;
    margin: auto !important;
}

.objectivesKanBan .webix_kanban_footer {
    padding-left: 6px
}


/* .objectivesKanBan .webix_kanban_icon {
    display: none !important
} */

.webix_kanban_user_avatar{
        display: none !important;
}
</style>