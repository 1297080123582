<template>
    <div :title="payload.text" style="height: 100%;">
        <div class="w-100 d-flex" >
            <!-- <span style="font-weight: bold; margin-left: 2px">{{ payload.name }}</span> -->
            <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
            </span>
        </div>
        <div>
                <div class="pb-1">  
                    <span class="ms-1">Link Projects to Key Action</span>
                </div>
                <b-overlay :show="is_loading">
                <!-- <div class="d-flex">
                    <b-form-input v-model.lazy="filter_input" placeholder="Search Targets"></b-form-input>
                    <b-button class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div> -->
                <!-- <div class="d-flex">
                    <div class="modal-title m-1">
                        <span v-show="selectedName != ''">Selected Key Actions: </span> 
                    </div>
                </div> -->
                <!-- <vue-typeahead-bootstrap
                        ref="targetOrObjectiveSearch"
                        :data="searchList"
                        v-model="query"
                        placeholder="Search Target or Objective"
                        showOnFocus
                        @hit="selectedItemEvent"
                        @input="search"
                        prepend="Targets & Objectives:"
                    ></vue-typeahead-bootstrap> -->
                    
                <webix-ui style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
                <b-button type="submit" class='ms-auto mt-2' style="float: right" variant='primary' @click="emitChanges">Update Selection</b-button>
                </b-overlay>
        </div>
    </div>
</template>

<script>
import dateMixin from '@/mixins/dateMixin.js'
//mport draggable from 'vuedraggable';
import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods, ganttComputed, ganttMethods, performanceComputed, performanceMethods, peopleComputed, peopleMethods } from '@/state/helpers';
import { cloneDeep} from 'lodash'
import _ from 'lodash'
export default {
mixins: [dateMixin],
// components: {
//     draggable
// },
props:{
    payload:{
        required: true,
    },
    modal: {
        default: false
    }
},
data:() => ({
    is_loading: false,
    is_loading_all_linkable_plans: false,
    linkable_plans_data: [],
    show_all_linkable_plans: false,
    filter_input: '',
    users_example:[],
    combined_targets_array: [],
    selectedPlainId: 0,
    combined_objectives_array: [],
    change_objective_mode: false,
    canSearch: false,
    parent_name: '',
    notes: '',
    changesMade: false,
    selectedName: '',
    selectedType: '',
    viewChildren: false,
    key_actions_list_objectives: [],
    key_actions_list_targets: [],
    level_data: []
}),
watch:{
    // change_objective_mode: {
    //     handler() {
    //         if(this.change_objective_mode == true) {
    //             this.is_loading = true
    //             this.setTreeData();
    //             this.initflippedModal();
                
    //         } else {
    //             window.webix.$$('levelsTargetsObjectivesTree').destructor();
    //         }
    //     }
    // },
    
    selectedPlainId: {
        handler(newValue, oldValue){
            let tree = window.webix.$$('levelsTargetsObjectivesTree');
            //Remove the styling for previous item (we only have the plain id of the previous item)
            let objective_options = this.goal_objectives_tree;                  
                let objective_index = _.findIndex(objective_options, {id: Number(oldValue)}); 
                if(objective_index != -1){
                    let objective = objective_options[objective_index]
                    let oldValueId = `${this.payload.level_id}|objective-${objective.id}`
                    if(oldValueId && tree.exists(oldValueId)){
                        tree.removeCss(oldValueId, 'selected_style_details_modal')
                    }
                } else {
                    let target_options = this.targets;                  
                    let target_index = _.findIndex(target_options, {id: Number(oldValue)});
                    if(target_index != -1){
                        let target = target_options[target_index];
                        let oldValueId = `${this.payload.level_id}|target-${target.id}`
                        if(oldValueId && tree.exists(oldValueId)){
                            tree.removeCss(oldValueId, 'selected_style_details_modal')
                        }
                    } else {
                        if(oldValue && tree.exists(oldValue)){
                            tree.removeCss(oldValue, 'selected_style_details_modal')
                        }
                    }
                }
            //set the new styling 
            if(this.selectedPlainId){
                if(tree){//* will be und
                    if(this.selectedType == 'objective') {
                        let value = `${this.payload.level_id}|objective-${newValue}`
                        if(tree.exists(value)){
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                        
                    } else if(this.selectedType == 'target') {
                        let value = `${this.payload.level_id}|target-${newValue}`
                        if(tree.exists(value)){
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                        let oldValueId = `${this.payload.level_id}|target-${oldValue}`
                        if(oldValueId && tree.exists(oldValueId)){
                            tree.removeCss(oldValueId, 'selected_style_details_modal')
                        }
                    } else {
                        let value = newValue
                        if(tree.exists(value)){
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                    }
                    
                }
                /*if(window.webix.$$('userLevelsTree').exists)
                window.webix.$$('userLevelsTree').addCss(newValue, 'selected_tree_item')
                if(oldValue != undefined){
                    window.webix.$$('userLevelsTree').removeCss(oldValue, 'selected_tree_item')
                }*/
            }
        }
    },
},
computed:{
    ...levelComputed,
    ...peopleComputed,
    ...activityComputed,
    ...profileComputed,
    ...ganttComputed,
    ...performanceComputed,
    kanbanHistoryLogs(){
        if(!this.payload.kanban_list_logs){ return [] }
        
        return this.payload.kanban_list_logs;
    },
    ui(){
            let el = this;
            return{
                id: 'levelsTargetsObjectivesTree',
                view: 'tree',
                css: 'levelTree',
                scroll: true,
                drag: false,
                select: true,
                // //data: this.level_data,
                template: (obj, common) => {
                    let id = obj.id
                    if(isNaN(id)) {
                        if(id.includes('target') && !id.includes('project')) {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-target mr-1"></span><span class="normal-text tree_styles"> ${obj.name} - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        } else if(id.includes('objective') && !id.includes('project')) {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-objective mr-1"></span><span class="normal-text tree_styles"> ${obj.name}  - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        } else {
                            return `<span class="d-flex"><span class="checkboxStyling ms-1" style="margin-right: 2px">${common.checkbox(obj, common)} </span><span class="badge-item badge-item-project mr-1"><span class="normal-text bold tree_styles"> ${obj.name}</span></span>`;
                        }                       
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}`;
                    }
                },
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                    setTimeout(() => {
                    console.log("THIS IS READY")
                    let projects = cloneDeep(el.projects)
                    let key_actions_list1 = []
                    let key_actions_list2 = []
                    projects.forEach(( project )=>{
                        console.log(project)
                        if(project.linked_type == 'target') {
                            key_actions_list2.push(project.linkable_id);
                            console.log(this.key_actions_list2)
                            
                        }
                        if(project.linked_type == 'objective') {
                            key_actions_list1.push(project.linkable_id);
                            
                            console.log(this.key_actions_list1)
                        }
                    })
                    this.key_actions_list_targets = key_actions_list2
                    this.key_actions_list_objectives = key_actions_list1

                    console.log(this.key_actions_list_targets)
                    console.log(this.key_actions_list_objectives)

                    let targets_array = cloneDeep(el.level_targets);
                    let combined_targets = []
                    targets_array.forEach(( target )=>{ 
                        let branch_id = target.level_id
                        if(this.exists(branch_id)){
                            let mutated_target = cloneDeep(target);

                            if(this.key_actions_list_targets.includes(target.id)) {
                                mutated_target.id = `${branch_id}|target-${mutated_target.id}`
                                console.log(mutated_target.id);
                                combined_targets.push(target)
                                this.add(mutated_target, 0,branch_id);
                            }
                            
                        }
                    })
                    if(el.combined_targets_array.length == 0) {
                        let value = el.combined_targets_array
                        el.combined_targets_array = value.concat(combined_targets);
                        console.log(el.combined_targets_array)
                    }

                    // For now we using the level Objectives 
                    let data = el.current_objective_details
                    if(data) {
                        let objectives_array = cloneDeep(data.objectives);
                        //let combined_objectives = []
                        objectives_array.forEach((objective )=>{
                            //if(objective.id.includes('objective')) {
                                let branch_id = el.selected_level.id
                                if(this.exists(branch_id)){                               
                                    let mutated_objective = cloneDeep(objective);
                                    if(this.key_actions_list_objectives.includes(objective.id)) {
                                        mutated_objective.owner_name = objective.owner_name
                                        mutated_objective.id = `${branch_id}|objective-${mutated_objective.id}`
                                        console.log(mutated_objective.id);
                                        //combined_objectives.push(objective)
                                        this.add(mutated_objective, 0,branch_id);
                                    }                         
                                }
                            //}
                        })
                    }
                    projects.forEach(( project )=>{
                            let branch_id = null
                            if(project.linked_type == 'objective') {
                                branch_id = `${project.level_id}|objective-${project.linkable_id}`
                            }
                            if(project.linked_type == 'target') {
                                branch_id = `${project.level_id}|target-${project.linkable_id}`
                            }
                            if(project.linked_type == 'level') {
                                branch_id = project.level_id
                            }
                            if(this.exists(branch_id)){
                                let mutated_project = cloneDeep(project);
                                mutated_project.owner_name = project.owner_name
                                mutated_project.id = `${branch_id}|project-${mutated_project.id}`
                                //combined_objectives.push(objective)
                                this.add(mutated_project, 0,branch_id);
                            }
                        //}
                    })
                }, 200)

                    
                },
                on:{                  
                    onBeforeOpen(id) {
                        let num = Number(id)
                        if(num != isNaN) {
                           console.log(id)
                           el.level_to_load = Number(id)
                        }
                    }
                },
            }
        },
},
methods:{
    ...activityMethods,
    ...levelMethods,
    ...profileMethods,
    ...ganttMethods,
    ...performanceMethods,
    ...peopleMethods,
    filterProjectsData() {
            this.$nextTick(() => {
                console.log(this.selected_goal_objective)
                if(this.selected_goal_objective != -1) {
                    console.log("1")
                    let params = {
                        item_id: this.selected_goal_objective,
                        item_type: 'objective',
                        user_id: this.selected_user_id, 
                    }
                    this.getClientProjects(params).then(() => {    
                        // this.findMainLinkedProjects()      
                        // this.setProjectsData()
                        this.$emit('updated');
                        this.$bvModal.hide('linkedKeyActionsModal')
                    })
                } else if(this.selected_target != -1) {
                    console.log("2")
                        let params = {
                            item_id: this.selected_target,
                            item_type: 'target',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params).then(() => {
                            // this.findMainLinkedProjects();
                            // this.setProjectsData()
                            this.$emit('updated');
                            this.$bvModal.hide('linkedKeyActionsModal')
                        })
                } else {
                    console.log("3")
                        let params = {
                            item_id: this.selected_level.id,
                            item_type: 'level',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params).then(() => {
                            // this.setProjectsData()
                            // this.findMainLinkedProjects();
                            this.$emit('updated');
                            this.$bvModal.hide('linkedKeyActionsModal')
                        })
                }     
            })
        },
    emitChanges() {
       let tree = window.webix.$$('levelsTargetsObjectivesTree');
       //New Items
       let newLinks = []
       let data = tree.getChecked();
       if(data.length != 0) {
            data.forEach(element => {
                console.log(element)
                let first_value = element.split('|')[0]
                let value = element.split('|')[2]
                let id = null
                console.log(first_value)
                console.log(value)
                if(value)  {
                    console.log(1)
                    let new_value = element.split('|')[2]
                    id = new_value.split('-')[1]
                } else {
                    console.log(2)
                    console.log(element)
                    let new_value = element.split('|')[1]
                    id = new_value.split('-')[1]
                }
                console.log(id)
                console.log("!!!!!!");
                
                this.projects.forEach(project => {
                    if(project.id == id) {
                        newLinks.push(project)
                    }
                })
                console.log(value)
            })
       }
        this.$emit('selectedNewIDs', newLinks);   
    },
    setTreeData(){
        let temp_level_data = _.cloneDeep(this.levels);
        //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
        this.level_data = this.processLevels(temp_level_data);
    },
    processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
    },
    initflippedModal(){
        this.is_loading = true
        setTimeout(() => {
            let matches = []
            if(this.payload.length != 0) {
                this.payload.forEach(project => {
                    let branch_id = null
                    if(project.linked_type == 'objective') {
                        branch_id = `${project.level_id}|objective-${project.linkable_id}`
                    }
                    if(project.linked_type == 'target') {
                        branch_id = `${project.level_id}|target-${project.linkable_id}`
                    }
                    if(project.linked_type == 'level') {
                        branch_id = project.level_id
                    }

                    //let id = `${branch_id}|project-${project.id}`
                    matches.push(branch_id)
                })
            } else {
                matches.push(this.selected_level.id)
            }
            if(matches.length > 0){
                let tree = window.webix.$$('levelsTargetsObjectivesTree');
                    matches.forEach( match => {
                        console.log(match)
                        if(tree.exists(match)) {
                            console.log('getting in here')
                            let promises = [ this.loadDataFromParents(matches) ];
                            Promise.all(promises)
                            .then(()=>{
                                setTimeout(() => {
                                    //this.openTreeNode(matches, true);
                                    this.prepareTree();
                                    //this.is_loading = false
                                    //this.resetTypeahead();
                                }, 500) 
                                
                            })
                        }
                    })
                
            }
        }, 1500)
    },
    loadDataFromParents(id_array) {
        let tree = window.webix.$$('levelsTargetsObjectivesTree');
        let data = []
        if(tree){
            let item = tree.getItem(id_array[0]);
            while(item.$parent != 0){
                data.push(item.$parent);
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
        }
    },
    setUsers() {
        let user = { id: 1, src: this.payload.src };
        this.users_example.push(user)
    },
    openTreeNode(id_array, searched){
        console.log(id_array)
        let tree = window.webix.$$('levelsTargetsObjectivesTree');
        if(tree){
            let item = tree.getItem(id_array[0]);
            tree.closeAll()
            while(item.$parent != 0){
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
            if(searched) {
                tree.showItem(id_array[0]);
            }
        }
    },
    formatKanbanHistoryDate(date_value){
        return this.getSimpleDate(date_value);
    },
    reloadGoalObjectveData(){
            return new Promise( (resolve, reject) => {
                let params = {
                    level_id: this.current_level_id,
                    milestone_data: 1,
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                }).then(()=>{
                    resolve();
                    this.is_loading = false
                })
                .catch(()=>{
                    reject();
                })
            })
    },
    prepareTree() {
        //check items and open branches
        let tree = window.webix.$$('levelsTargetsObjectivesTree');
        //open branches
        tree.open(this.payload.level_id)
        let data_all = this.projects
        if(data_all.length != 0) {
            let array = []
            data_all.forEach(project => {
                let branch_id = null
                if(project.linked_type == 'objective') {
                    branch_id = `${project.level_id}|objective-${project.linkable_id}`
                }
                if(project.linked_type == 'target') {
                    branch_id = `${project.level_id}|target-${project.linkable_id}`
                }
                if(project.linked_type == 'level') {
                    branch_id = project.level_id
                }
                
                array.push(branch_id)
                
                tree.open(branch_id)
            })
        }
        //Check Items
        let data = this.payload
        console.log(data)
        if(data.length != 0) {
            data.forEach(project => {
                let branch_id = null
                if(project.linked_type == 'objective') {
                    branch_id = `${project.level_id}|objective-${project.linkable_id}`
                }
                if(project.linked_type == 'target') {
                    branch_id = `${project.level_id}|target-${project.linkable_id}`
                }
                if(project.linked_type == 'level') {
                    branch_id = project.level_id
                }
                tree.checkItem(`${branch_id}|project-${project.id}`)
            })
        }
        this.$nextTick(() => {
            this.is_loading = false
        })
    }
},
beforeDestroy() {
    window.webix.$$('levelsTargetsObjectivesTree').destructor();
    this.modal_payload = {}
},
mounted(){
    this.loadPerformanceTargets(this.selected_level.id).then(()=>{
        this.reloadGoalObjectveData()
    })
    this.is_loading = true
    this.setUsers();
    this.setTreeData();
    this.$nextTick(() => {
        this.initflippedModal();
    })
    
},
}
</script>
<style>
#kanbanModal___BV_modal_header_ {
    background-color: #EBEDF0;
}
.low_color {
    background-color: rgb(107, 168, 203) !important;
}
.medium_color {
    background-color: #f1734f !important;
}
.high_color {
    background-color: red !important;
}
.label {
    font-weight: bold;
}

.checkboxStyling .webix_tree_checkbox {
    padding-top: 2px
}

.selected_style_details_modal  {
    /* border: 2px black !important; */
    color: #0275d8 !important;
    font-weight: bold;
}

#movementHistoryList.verti-timeline .event-list {
    padding: 0px 0px 10px 30px;
}
</style>