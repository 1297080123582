<template>
    <b-modal id="kanbanModal" class="custom-modal-width" hide-footer :title="payload.text" style="height: 100%;"> 
        <template #modal-header>
            <div class="w-100 d-flex">
                <span class="badge-item badge-item-key-action"></span>
                <div class="w-100 d-flex" style="justify-content: space-between;">
                    <span style="font-weight: bold; margin-left: 2px">{{ KAname }}</span>
                    <div class="mb-1" v-if="!edit_key_action_mode">  
                    <b-button v-if="$hasPerm('Power User') || isOwnerOrResponsibleUser" @click="edit_key_action_mode = true" variant="primary" class="ms-1" size="sm">
                        Edit Key Action      
                    </b-button>
                    <b-button v-if="$hasPerm('Power User') || payload.owner_user_id == core.id" @click="deleteKeyAction()" variant="danger" class="ms-1" size="sm">
                        Delete Key Action      
                    </b-button>
                    </div>
                </div>        
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('kanbanModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>
            </div>
        </template>
        
        <!-- <div class="modal-title">
           Details   

        </div> -->
        <div v-if="refreshDataDisplay">
            

        <div v-show="!change_objective_mode && !change_project_mode && !edit_key_action_mode" class="mt-0">
            <b-overlay :show="is_loading">
            <div> 
                <div>
                    <b-tabs>
                        <b-tab title="Details" active>
                            <p size="sm" class="m-0 mt-2" style="font-weight: bold;">Linked Parent:</p>
                            <div>
                                <div class="d-flex" style="justify-content: space-between;">
                                    <div class="d-flex">  
                                        <span v-if="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                                        <span v-if="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                                        <span v-if="selectedType == 'level'" class="badge-item badge-item-level mr-1"></span>
                                        <span style="margin-left: 2px">{{ selectedName }}</span> 
                                    </div>
                                    <b-button v-if="payload.link_type == 'objective' && canSearch && !edit_key_action_mode" variant="primary" size="sm" class="mt-1" style="float: right; white-space: nowrap; height: 24px; justify-content: flex-end;" @click="selectGanttObjOrKR()">
                                    Select Objective in Gantt
                                    </b-button>
                                </div>
                                <!-- <div class="d-flex" style="justify-content: space-between;" v-if="payload.key_result_name">
                                    <div class="d-flex">
                                        <span class="badge-item badge-item-key-result mr-1"></span>
                                        <span class="badge-item badge-item-key-result mr-1"></span>
                                        <span class="badge-item badge-item-key-result mr-1"></span>
                                        <span style="margin-left: 2px">{{ payload.key_result_name }}</span>
                                    </div>
                                    <b-button variant="primary" size="sm" class="mt-1" style="float: right; white-space: nowrap; height: 24px" @click="selectGanttObjOrKR()">
                                    Select Key Result in Gantt
                                    </b-button>
                                </div> -->
                                
                            </div>
                            <p size="sm" class="m-0 mt-2" style="font-weight: bold;">Linked Projects:</p>
                            <div>
                                <div class="d-flex" style="justify-content: space-between;">
                                    <div class="d-flex">  
                                        <!-- <span v-if="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                                        <span v-if="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                                        <span v-if="selectedType == 'level'" class="badge-item badge-item-level mr-1"></span> -->
                                        
                                        <span v-if="linked_projects.length != 0"><span v-for="(item, idx) in linked_projects" :key="idx">{{ item.name }} <span v-if="idx == 0 && linked_projects.length != 1"> , </span> </span><i class="me-1 fas fa-pen" v-if="edit_key_action_mode" style="margin-left: 5px; margin-top: 3px"></i></span>
                                        <span v-if="linked_projects.length == 0" style="margin-left: 2px">No Projects Linked</span> 
                                    </div>
                                </div>    
                            </div>   
                            
                            <!-- <div>
                                <div class="d-flex cursor-pointer">
                                    <p size="sm" class="m-0 mt-2" style="font-weight: bold;">Key Action Details</p>
                                    <i class="fas fa-pen" @click="edit_key_action_mode = true" style="margin-left: 5px; margin-top: 10px"></i>
                                </div>    
                            </div>  -->
                            <div class="row mt-2">
                                <div class="col-sm">
                                    <p class="m-1"><b>Priority:</b> <b-badge v-show="payload.priority" variant="primary" style="border-radius: 0.2rem; margin-right: 3px; font-weight: normal;" :class="this.setColor()" class="text-capitalize">{{ payload.priority }}</b-badge> <span v-show="!payload.priority">No Priority</span></p>
                                    <p class="m-1"><b>Year:</b> {{ payload.year }}</p>
                                    <p class="m-1" v-if="payload.status_value != -2 && payload.status_value != -1" ><b>Status:</b> {{ payload.status_value }}% </p>
                                    <p class="m-1" v-if="payload.status_value == '-2'"><b>Status:</b> Archived </p>
                                    <p class="m-1" v-if="payload.status_value == '-1'"><b>Status:</b> Delayed </p>
                                    <p class="m-1"><b>Start:</b> {{ payload.formatted_start_date }} </p>
                                    <p class="m-1"><b>End:</b> {{ payload.formatted_end_date  }} </p>
                                </div>
                                <div class="col-sm">
                                    <p class="m-1"><b>Owner:</b> {{ payload.owner_user_name }} </p> 
                                    <p class="m-1"><b>Responsible:</b> {{ payload.responsible_user_name }} </p> 
                                    <p class="m-1"><b>Structure:</b> {{ payload.structure }} </p> 
                                    <p class="m-1"><b>Quarter:</b> {{ payload.quarter }} </p> 
                                     
                                </div>
                            </div>
                            <p class="m-1"><b>Task Team</b></p>
                            <div class="avatar-group">
                                <div v-for="(user, idx) in users_example" :key="idx" class="avatar-group-item">
                                <a class="d-inline-block">
                                    <img :src="user.src" alt=""  class="rounded-circle avatar-xs cursor-pointer">
                                </a>
                                </div>
                            </div>
                            <br>
                            <b-form>
                                <b-form-textarea :disabled="true" v-model="notes" rows="3"></b-form-textarea>
                            </b-form>
                        </b-tab>
                        <b-tab title="Movement History">
                            <b-alert :show="kanbanHistoryLogs.length == 0">
                                No history available
                            </b-alert>
                            <div data-simplebar class="mt-3" style="max-height: 280px">
                                <ul id="movementHistoryList" class="verti-timeline list-unstyled">
                                    <li class="event-list" v-for="(item, idx) in kanbanHistoryLogs" :key="idx">
                                        <div class="event-timeline-dot">
                                        <i class="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div class="media">
                                            <div class="media-body">
                                                <div>
                                                    <strong class="me-1">{{ item.user_name }}:</strong>{{ item.action }}
                                                </div>
                                                <div class="small-text">{{ formatKanbanHistoryDate(item.updated_at) }}</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </b-overlay>
        </div>
        <div v-if="change_objective_mode">
                <div class="pb-1">  
                    <b-button @click="change_objective_mode = false" variant="lightr" size="sm">
                        <font-awesome-icon icon="fa-arrow-left"/>
                    </b-button>
                    <span class="ms-1">Link Key Action to either a Level, Target or Objective</span>
                </div>
                <b-overlay :show="is_loading">
                <!-- <div class="d-flex">
                    <b-form-input v-model.lazy="filter_input" placeholder="Search Targets"></b-form-input>
                    <b-button class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div> -->
                <div class="d-flex">
                    <div class="modal-title m-1">
                        <span v-show="selectedName != ''">Selected {{ selectedType }} : </span> 
                        <span v-show="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                        <span v-show="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                        <!-- <span class="badge-item badge-item-objective mr-1"></span> -->
                        <span style="font-weight: bold" class="text-primary">{{selectedName}}</span>
                    </div>
                </div>  
                <!-- <vue-typeahead-bootstrap
                        ref="targetOrObjectiveSearch"
                        :data="searchList"
                        v-model="query"
                        placeholder="Search Target or Objective"
                        showOnFocus
                        @hit="selectedItemEvent"
                        @input="search"
                        prepend="Targets & Objectives:"
                    ></vue-typeahead-bootstrap> -->
                    
                <webix-ui v-if="change_objective_mode && edit_key_action_mode" style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
                </b-overlay>
        </div>
        <div v-if="edit_key_action_mode">
            
            <div class="m-1">

            
            <span>
                <span v-if="edit_key_action_mode && !change_objective_mode && !change_project_mode">
                    <p size="sm" class="m-0 mt-2" style="font-weight: bold;">Linked Parent:</p>
                    <div class="d-flex cursor-pointer" style="justify-content: space-between;">
                        <div class="d-flex" @click="change_objective_mode = true">  
                            <span v-if="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                            <span v-if="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                            <span v-if="selectedType == 'level'" class="badge-item badge-item-level mr-1"></span>
                            <span style="margin-left: 2px">{{ selectedName }} <i class="me-1 fas fa-pen" style="margin-left: 5px; margin-top: 3px"></i></span> 
                        </div>
                        <b-button v-if="payload.link_type == 'objective' && canSearch && !edit_key_action_mode" variant="primary" size="sm" class="mt-1" style="float: right; white-space: nowrap; height: 24px; justify-content: flex-end;" @click="selectGanttObjOrKR()">
                        Select Objective in Gantt
                        </b-button>
                    </div>
                    
                    <!-- <div class="d-flex" style="justify-content: space-between;" v-if="payload.key_result_name">
                        <div class="d-flex">
                            <span class="badge-item badge-item-key-result mr-1"></span>
                            <span class="badge-item badge-item-key-result mr-1"></span>
                            <span class="badge-item badge-item-key-result mr-1"></span>
                            <span style="margin-left: 2px">{{ payload.key_result_name }}</span>
                        </div>
                        <b-button variant="primary" size="sm" class="mt-1" style="float: right; white-space: nowrap; height: 24px" @click="selectGanttObjOrKR()">
                        Select Key Result in Gantt
                        </b-button>
                    </div> -->
                    
                </span>
                <span v-if="edit_key_action_mode && !change_objective_mode && !change_project_mode" class="mb-2">
                    <p size="sm" class="m-0 mt-2" style="font-weight: bold;">Linked Projects:</p>
                    <div>
                        <div class="d-flex cursor-pointer" style="justify-content: space-between;">
                            <div class="d-flex" @click="change_project_mode = true">  
                                <!-- <span v-if="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                                <span v-if="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                                <span v-if="selectedType == 'level'" class="badge-item badge-item-level mr-1"></span> -->
                                
                                <span v-if="linked_projects.length != 0"><span v-for="(item, idx) in linked_projects" :key="idx">{{ item.name }} <span v-if="idx == 0 && linked_projects.length != 1"> , </span> </span><i class="me-1 fas fa-pen" v-if="edit_key_action_mode" style="margin-left: 5px; margin-top: 3px"></i></span>
                                <span v-if="linked_projects.length == 0" style="margin-left: 2px">No Projects Linked <i class="me-1 fas fa-pen" style="margin-left: 5px; margin-top: 3px"></i></span> 
                            </div>
                        </div>    
                    </div>   
                </span>
            </span>
            <editActionPlan v-if="edit_key_action_mode && !change_objective_mode && !change_project_mode" class="mt-3" :payload="payload" @back="!edit_key_action_mode" @saved="refreshData" />
            <div class="" v-if="edit_key_action_mode && !change_objective_mode && !change_project_mode">  
                <b-button @click="edit_key_action_mode = false; resetName()" style="float: right; margin-right: 0.5rem" variant="secondary" size="sm">
                    <span class="mr-1">
                        Back
                    </span>
            </b-button>
            </div>  
        </div>
        </div>
        <div >
            <projectsTree v-if="change_project_mode" :payload="linked_projects" @selectedNewIDs="updateProjectsList" />
        </div>
        </div>
    </b-modal>
</template>

<script>
import dateMixin from '@/mixins/dateMixin.js'
//mport draggable from 'vuedraggable';
import { levelComputed, activityComputed, activityMethods, profileComputed, profileMethods, ganttComputed, ganttMethods, performanceComputed, performanceMethods, peopleComputed, peopleMethods, adminComputed, adminMethods } from '@/state/helpers';
import { cloneDeep } from 'lodash'
import editActionPlan from '../editActionPlan';
import projectsTree from '../projectsTree.vue';
import _ from 'lodash'
export default {
mixins: [dateMixin],
components: {
    projectsTree,
    editActionPlan
},
props:{
    payload:{
        required: true,
    },
},
data:() => ({
    is_loading: false,
    is_loading_all_linkable_plans: false,
    linkable_plans_data: [],
    show_all_linkable_plans: false,
    refreshDataDisplay: true,
    edit_key_action_mode: false,
    filter_input: '',
    users_example:[],
    combined_targets_array: [],
    selectedPlainId: 0,
    combined_objectives_array: [],
    change_objective_mode: false,
    change_project_mode: false,
    canSearch: false,
    parent_name: '',
    KAname: '',
    notes: '',
    changesMade: false,
    selectedName: '',
    selectedType: '',
    viewChildren: false,
    query: '',
    projectsData: [],
    main_linked_projects: [],
    linked_project_ids: [],
    linked_projects: [],
    test: [],
    linkable_projects_changes_made: false
}),
watch:{
    // payload: function(newVal, oldVal) { // watch it
    //       console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    //     },
    payload: {
        deep: true,
        handler() {
            this.$nextTick(() => {
            this.filterProjectsData()
            console.log(JSON.parse(JSON.stringify(this.payload)))
            if(this.payload.milestone_objective_id) {
                if(this.payload.parent != 'No linked Parent') {
                    if(this.payload.parent.text) {
                    this.selectedName = this.payload.parent.text
                    } else {
                        this.selectedName = this.payload.parent.name
                    }
                }
                if(this.payload.searchable) {
                    this.selectedId = `${this.payload.level_id}|objective-${this.payload.milestone_objective_id}`
                } else {
                    console.log('getting in here')
                    this.selectedId = this.payload.level_id
                }
                this.selectedId = `${this.payload.level_id}|objective-${this.payload.milestone_objective_id}`
            } else if(this.payload.target_id != null) {
                this.selectedName = this.payload.parent.name
                this.selectedId = `${this.payload.level_id}|target-${this.payload.target_id}`
            } else {
                this.selectedId = this.payload.level_id
                this.selectedName = this.payload.parent
            }
            this.KAname = this.payload.name
            this.canSearch = this.payload.searchable
            this.initModal();
            this.setUsers();
            this.selectedType = this.payload.link_type
            //this.populateList();
            this.setTreeData();
            this.findLinkedProjects();
            this.setProjectsData();
            this.notes = this.payload.notes
        })
           
        }
    },
    change_objective_mode: {
        handler() {
            if(this.change_objective_mode == true) {
                console.log("1")
                this.is_loading = true
                console.log('building the tree')
                this.setTreeData();
                this.initflippedModal();
                
            } else {
                console.log("2")
                window.webix.$$('levelsTargetsObjectivesTree').destructor();
            }
        }
    },
},
computed:{
    ...levelComputed,
    ...peopleComputed,
    ...activityComputed,
    ...profileComputed,
    ...ganttComputed,
    ...performanceComputed,
    ...adminComputed,
    isOwnerOrResponsibleUser(){
        let responsible_user = false
        if(this.payload.responsible_user == this.core.id) {
            responsible_user = true
        }
        let isLinkedToProject = false
        this.projectsData.forEach(data => {
            this.linked_projects.forEach(project => {
                if(project.id == data.id) {
                    if(data.owner_user_id == this.core.id) {
                        isLinkedToProject = true
                    }
                    data.responsible_users.forEach(user => {
                        if(user.id == this.core.id) {
                            isLinkedToProject = true
                        }
                    })
                }
            })
           
        })
        let is_owner = Number(this.core.id) == Number(this.payload.owner_user_id);
        return is_owner || responsible_user || isLinkedToProject;
    },
    kanbanHistoryLogs(){
        if(!this.payload.kanban_list_logs){ return [] }
        
        return this.payload.kanban_list_logs;
    },
    ui(){
            let el = this;
            return{
                id: 'levelsTargetsObjectivesTree',
                view: 'tree',
                css: 'levelTree',
                scroll: true,
                drag: false,
                select: true,
                // //data: this.level_data,
                template: (obj, common) => {
                    let id = obj.id
                    if(isNaN(id)) {
                        console.log(obj)
                        if(id.includes('target')) {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-target mr-1"></span><span class="normal-text tree_styles"> ${obj.name} - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        } else {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-objective mr-1"></span><span class="normal-text tree_styles"> ${obj.text}  - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        }                       
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}`;
                    }
                },
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                    let targets_array = cloneDeep(el.targets);
                        let combined_targets = []
                        targets_array.forEach(( target )=>{ 
                            let branch_id = target.level_id
                            if(this.exists(branch_id)){
                                let mutated_target = cloneDeep(target);
                                mutated_target.id = `${branch_id}|target-${mutated_target.id}`
                                console.log(mutated_target.id);
                                combined_targets.push(target)
                                this.add(mutated_target, 0,branch_id);
                            }
                        })
                        if(el.combined_targets_array.length == 0) {
                            let value = el.combined_targets_array
                            el.combined_targets_array = value.concat(combined_targets);
                            console.log(el.combined_targets_array)
                        }

                        let objectives_array = cloneDeep(el.gantt_tasks);
                        let combined_objectives = []
                        objectives_array.forEach((objective )=>{ 
                            if(objective.id.includes('objective')) {
                                let branch_id = el.selected_level.id
                                if(this.exists(branch_id)){
                                    let mutated_objective = cloneDeep(objective);
                                    if(mutated_objective.$parent != 0) {
                                        let objective = el.current_objective_details.objectives.find( objective => { return objective.id == mutated_objective.plain_id; })
                                        console.log(JSON.parse(JSON.stringify(objective)))
                                        if(objective) {
                                            mutated_objective.owner_name = objective.owner_name
                                        }
                                    } else {
                                        mutated_objective.user_name = 'No Owner'
                                    }
                                    mutated_objective.id = `${branch_id}|objective-${mutated_objective.plain_id}`
                                    console.log(mutated_objective.id);
                                    combined_objectives.push(objective)
                                    this.add(mutated_objective, 0,branch_id);
                                }
                            }
                        })
                        if(el.combined_objectives_array.length == 0) {
                            let value = el.combined_objectives_array
                            el.combined_objectives_array = value.concat(combined_objectives);
                            console.log(el.combined_objectives_array)
                        }
                },
                on:{
                    onItemClick(id){
                        console.log(id)
                        //let el = this.$scope.$parent;
                        if(id.includes('target')) {
                            console.log(id);
                            let name = ''
                            let mainId = id.split('|')[1];
                            let level_id = id.split('|')[0];
                            let target_id = mainId.split('-')[1]
                            console.log(target_id);
                            //set to combined array
                            el.targets.forEach(item => {
                                if(item.id == target_id) {
                                    name = item.name
                                }
                            })
                            console.log(name)
                            el.selectedType = 'target'
                            el.selectedName = name
                            el.selectedPlainId = target_id
                            el.changesMade = true
                            el.selectedId = `${level_id}|target-${target_id}`
                            el.change_objective_mode = false
                            //el.clickItemEvent(name)
                        } else if(id.includes('objective')) {
                            console.log(id);
                            let name = ''
                            let mainId = id.split('|')[1];
                            let level_id = id.split('|')[0];
                            let objective_id = mainId.split('-')[1]
                            //set to combined array
                            el.gantt_tasks.forEach(item => {
                                if(item.id.includes('objective')) {
                                    if(item.plain_id == objective_id) {
                                       name = item.text
                                    }
                                }
                                
                            })
                            console.log(name)
                            el.selectedType = 'objective'
                            el.selectedName = name
                            el.selectedPlainId = objective_id
                            el.changesMade = true
                            el.selectedId = `${level_id}|objective-${objective_id}`
                            el.change_objective_mode = false
                        } else {
                            el.selectedType = 'level'
                            console.log(id)
                            let index = _.findIndex(el.levels, {id: Number(id)});
                            console.log(index)
                            let name = ''
                            if( index != -1){
                                name = el.levels[index].name;
                            }
                            el.selectedName = name
                            el.selectedId = id
                            el.changesMade = true
                            el.change_objective_mode = false
                        }
                    },
                    onBeforeOpen(id) {
                        let num = Number(id)
                        if(num != isNaN) {
                           console.log(id)
                           el.level_to_load = Number(id)
                        }
                    }
                },
            }
        },
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...ganttMethods,
    ...performanceMethods,
    ...peopleMethods,
    ...adminMethods,
    updateProjectsList(data) {
       console.log(JSON.parse(JSON.stringify(data)))
       let ids = []
       if(data.length != 0) {
        data.forEach(item => {
            ids.push(item.id)
        })
       }
       this.linked_project_ids = ids
       this.change_project_mode = !this.change_project_mode
       this.linked_projects = data
    },
    deleteKeyAction(){
        this.$swal.fire({
            title: "Delete Action Plan?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Delete",
        }).then((result) => {
            console.log(result);
            if(result.isConfirmed){
                this.deleteObjectiveTask({plan_id: this.payload.id})
                .then(()=>{
                    this.$bvModal.hide('kanbanModal')
                    this.$emit('saved');
                })
            }
        });        
    },
    setTreeData(){
        let temp_level_data = _.cloneDeep(this.levels);
        //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
        this.level_data = this.processLevels(temp_level_data);
    },
    processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
    },
    initflippedModal(){
        this.is_loading = true
        setTimeout(() => {
            let matches = []
                //if(this.selectedId.includes('target') || this.selectedId.includes('objective')) {
            matches.push(this.selectedId);
            if(matches.length > 0){
                let tree = window.webix.$$('levelsTargetsObjectivesTree');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            let promises = [ this.loadDataFromParents(matches) ];
                            Promise.all(promises)
                            .then(()=>{
                                setTimeout(() => {
                                    tree.addCss(matches[0], 'selected_style_details_modal');
                                    this.openTreeNode(matches, true);
                                    this.is_loading = false
                                    //this.resetTypeahead();
                                }, 1500) 
                                
                            })
                        } else {
                            this.is_loading = false
                        }
                    })
                
            }
        }, 800)
    },
    loadDataFromParents(id_array) {
        let tree = window.webix.$$('levelsTargetsObjectivesTree');
        let data = []
        if(tree){
            let item = tree.getItem(id_array[0]);
            while(item.$parent != 0){
                data.push(item.$parent);
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
        }
        console.log(data)
        // data.forEach(itemID => {
        //     this.loadPerformanceTargets(itemID).then(()=>{
        //         this.buildTree();
        //         this.populateTargetList()
        //         this.show_add_target = false;
        //     })
        // })
    },
    selectGanttObjOrKR() {
        let id = null;
        if(this.payload.milestone_objective_id){
            id = 'Objective'
        } else {
            id = 'KR'
        }
        console.log(id)
            if(this.show_gantt) {
            this.selectInGantt()
            } else {
                this.$swal.fire({
                    title: "Open Gantt and select " + id,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Open Gantt",
                }).then((result) => {
                    console.log(result);
                    if(result.isConfirmed){
                        this.is_loading = true
                        this.setShowGantt(true).then(() => {
                            setTimeout(() => {
                                this.selectInGantt()
                            },3000)    
                        })
                    }
               });            
        }
        
    },
    selectInGantt() {

        this.$bvModal.hide('kanbanModal')
        let table = window.webix.$$('myGanttTree');
        let item =  this.kanban_data.find( key_action => {
            return key_action.id == this.payload.id
        });
        console.log(item);

        // if item not found, stop here
        if(item == undefined){
            return;
        }
        let parent_id = 0
        // if(item.milestone_objective_id == null) {
        //     parent_id = item.milestone_objective_id;
        // } else {
        //     parent_id = item.key_result_id;
        // }
        
        //* check if parent is milestone or key result
        if(item.key_result_id != null){
            parent_id = `kr-${item.key_result_id}`;
        }
        else if(item.milestone_objective_id != null){
            parent_id = `objective-${item.milestone_objective_id}`;
        }
        else{
            return;
        }
        // console.log('PARENT', parent_id)
        
        //now we have found the item, we find its top most parent
        console.log(parent_id);
        item = table.getItem(parent_id);
        console.log(item);
        if(item.$parent != 0) {
            while(item.$parent != 0){
            table.open(item.$parent);
            item = table.getItem(item.$parent);
            table.showItem(parent_id);
            table.select(parent_id);
            }
        } else {
            table.showItem(parent_id);
            table.select(parent_id);
        }
        this.$emit('scroll_to_gantt');
        this.is_loading = false
  
    },
    initModal(){
    },
    setUsers() {
        this.users_example = []
        let user = { id: 1, src: this.payload.src };
        this.users_example.push(user)
    },
    setColor() {
        switch(this.payload.priority) {
            case 'low':
                return 'low_color';
            case 'medium':
                return 'medium_color';
            case 'high':
                return 'high_color';
            default:
                return 'low_color';
        }
    },
    openTreeNode(id_array, searched){
        console.log(id_array)
        let tree = window.webix.$$('levelsTargetsObjectivesTree');
        if(tree){
            let item = tree.getItem(id_array[0]);
            tree.closeAll()
            while(item.$parent != 0){
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
            this.is_loading = false
            if(searched) {
                tree.showItem(id_array[0]);
            }
        }
    },
    updateNote(form) {
        console.log(JSON.parse(JSON.stringify(form)))
        let data = form
        // let form = {
        //     id: this.payload.id,
        //     parent_id: this.payload.parent_id,
        //     level_id: this.payload.level_id,
        //     objective_id: this.payload.milestone_objective_id,
        //     target_id: this.payload.target_id,
        //     key_result_id: this.payload.key_result_id,
        //     name: this.payload.name,
        //     start_date: this.payload.start_date,
        //     end_date: this.payload.end_date,
        //     type:  this.payload.type,
        //     description: this.payload.description,
        //     body: this.payload.body,
        //     notes: this.notes,
        //     status: this.payload.status,
        //     priority: this.payload.priority,
        //     owner_id: this.payload.owner_user_id,
        //     responsible_user_id: this.payload.responsible_user_id,
        // }
        console.log(this.changesMade)
        console.log(this.selectedType)
        form['linked_project_ids'] = this.linked_project_ids
        if(this.changesMade && this.selectedType == 'objective') {
            form['objective_id'] = Number(this.selectedPlainId)
            form['target_id'] = null
        }
        if(this.changesMade && this.selectedType == 'target') {
            form['objective_id'] = null
            form['target_id'] = Number(this.selectedPlainId)
        }
        if(this.changesMade && this.selectedType == 'level') {
            form['target_id'] = null
            form['objective_id'] = null
            form['level_id'] = Number(this.selectedId)
        }
        if(!this.changesMade) {
            form['target_id'] = data.target_id
            form['objective_id'] = data.objective_id
            form['level_id'] = data.level_id
        }
        let activity_params = {
            key_actions: [data],
        }
        this.saveActivityInbox(activity_params).then(() => {
            this.$nextTick(() => {

                this.filterProjectsData()
                //this.findLinkedProjects();
                this.$emit('savedNewDetails', data.id);
                
            })
        })
    },
    formatKanbanHistoryDate(date_value){
        return this.getSimpleDate(date_value);
    },
    reloadGoalObjectveData(){
            return new Promise( (resolve, reject) => {
                let params = {
                    level_id: this.current_level_id,
                    milestone_data: 1,
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                }).then(()=>{
                    resolve();
                    this.is_loading = false
                })
                .catch(()=>{
                    reject();
                })
            })
    },
    setProjectsData() {
        //this.linked_projects = []
        let projectsData = [];
        this.projects.forEach((project) => {
            if(this.linked_projects.length != 0) {
                let data = this.linked_projects
                //data.forEach(item => {
                    if(!data.includes(project)) {
                        projectsData.push(project);
                    }
                //})
            } else {
                projectsData.push(project);
            }
        });
        this.projectsData = projectsData
        
    },
    findLinkedProjects() {
        let projectsList = []
        this.projects.forEach(project => {
            if(project.objective_plans.length != 0) {
                let plans = project.objective_plans
                plans.forEach(plan => {
                    if(plan.id == this.payload.id) {
                        projectsList.push(project);
                    }
                })
            }
        })
        this.main_linked_projects = projectsList
        this.linked_projects = projectsList
    },
    findMainLinkedProjects() {
        let projectList = []
        this.projects.forEach(project => {
            if(project.objective_plans.length != 0) {
                let plans = project.objective_plans
                plans.forEach(plan => {
                    if(plan.id == this.payload.id) {
                        projectList.push(project);
                    }
                })
            }
        })
        this.main_linked_projects = projectList
    },
    unlinkItem(event) {
        let value = this.linked_projects.filter(project => project != event);
        console.log(value)
        this.linked_projects = value
        this.linkable_projects_changes_made = true
        this.setProjectsData();
    },
    selectProject(event) {
        this.query = ''
        console.log(event)
        let items = this.linked_projects
        let value = items.slice();
        items.push(event);
        this.main_linked_projects = value
        this.linked_projects = items
        //this.change_project_mode = false
        this.linkable_projects_changes_made = true
        this.setProjectsData()
    },
    filterProjectsData() {
            this.$nextTick(() => {
                if(this.selected_goal_objective != -1) {
                    console.log("1")
                    let params = {
                        item_id: this.selected_goal_objective,
                        item_type: 'objective',
                        user_id: this.selected_user_id, 
                    }
                    this.getClientProjects(params).then(() => {    
                        this.findMainLinkedProjects()      
                        this.setProjectsData()
                        this.change_project_mode = false
                    })
                } else if(this.selected_target != -1) {
                    console.log("2")
                        let params = {
                            item_id: this.selected_target,
                            item_type: 'target',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params).then(() => {
                            this.findMainLinkedProjects();
                            this.setProjectsData()
                            this.change_project_mode = false
                        })
                } else {
                    console.log("3")
                        let params = {
                            item_id: this.selected_level.id,
                            item_type: 'level',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params).then(() => {
                            this.setProjectsData()
                            this.findMainLinkedProjects();
                            this.change_project_mode = false
                        })
                }     
            })
        },
    resetName() {
        if(this.payload.milestone_objective_id) {
        if(this.payload.parent != 'No linked Parent') {
            if(this.payload.parent.text) {
            this.selectedName = this.payload.parent.text
            } else {
                this.selectedName = this.payload.parent.name
            }
        }
        if(this.payload.searchable) {
            this.selectedId = `${this.payload.level_id}|objective-${this.payload.milestone_objective_id}`
        } else {
            console.log('getting in here')
            this.selectedId = this.payload.level_id
        }
        this.selectedId = `${this.payload.level_id}|objective-${this.payload.milestone_objective_id}`
        } else if(this.payload.target_id != null) {
            this.selectedName = this.payload.parent.name
            this.selectedId = `${this.payload.level_id}|target-${this.payload.target_id}`
        } else {
            this.selectedId = this.payload.level_id
            this.selectedName = this.payload.parent
        }
        this.selectedType = this.payload.link_type
    },
    // saveChanges() {
    //     let new_items = this.linked_projects
    //     //Remove old items
    //     if(this.main_linked_projects.length != 0) {
    //         this.main_linked_projects.forEach(projectName => {
    //             if(!new_items.includes(projectName)) {
    //                 let old_item = null
    //                 this.projects.forEach(project => {
    //                     if(projectName == project.name) {
    //                         old_item = project
    //                     }

    //                 })
    //                 //project link has been removed 
    //                 console.log("Removing")
    //                 let params = {project_id: old_item.id, key_action_id: this.payload.id}
    //                 this.unLinkProjectKA(params)
    //             }
    //         })
    //     }
    //     this.filterProjectsData()
    //     //Add new items 
    //         new_items.forEach(projectName => {
    //             if(!this.main_linked_projects.includes(projectName)) {
    //                 //find new items id
    //                 let new_item = null
    //                 this.projects.forEach(project => {
    //                     if(projectName == project.name) {
    //                         new_item = project
    //                     }

    //                 })
    //                //Add new Item
    //                console.log("Saving")
    //                if(new_item != undefined) {
    //                     let params = {project_id: new_item.id, key_action_id: this.payload.id}
    //                     this.linkProjectKA(params)
    //                } 
    //             }
    //         })
    // },
    refreshData(form) {
        this.edit_key_action_mode = false,
        this.updateNote(form)
    }
},
beforeDestroy() {
    this.filterProjectsData()
    this.modal_payload = {}
},
mounted(){
    // this.loadPerformanceTargets(this.selected_level.id).then(()=>{
    //     this.reloadGoalObjectveData()
    // })
    this.filterProjectsData()
    console.log(JSON.parse(JSON.stringify(this.payload)))
    if(this.payload.milestone_objective_id) {
        if(this.payload.parent != 'No linked Parent') {
            if(this.payload.parent.text) {
            this.selectedName = this.payload.parent.text
            } else {
                this.selectedName = this.payload.parent.name
            }
        }
        if(this.payload.searchable) {
            this.selectedId = `${this.payload.level_id}|objective-${this.payload.milestone_objective_id}`
        } else {
            console.log('getting in here')
            this.selectedId = this.payload.level_id
        }
        this.selectedId = `${this.payload.level_id}|objective-${this.payload.milestone_objective_id}`
    } else if(this.payload.target_id != null) {
        this.selectedName = this.payload.parent.name
        this.selectedId = `${this.payload.level_id}|target-${this.payload.target_id}`
    } else {
        this.selectedId = this.payload.level_id
        this.selectedName = this.payload.parent
    }
    this.KAname = this.payload.name
    this.canSearch = this.payload.searchable
    this.initModal();
    this.setUsers();
    this.selectedType = this.payload.link_type
    //this.populateList();
    this.setTreeData();
    this.findLinkedProjects();
    this.setProjectsData();
    this.notes = this.payload.notes
    
},
}
</script>
<style>
#kanbanModal___BV_modal_header_ {
    background-color: #EBEDF0;
}
.low_color {
    background-color: rgb(107, 168, 203) !important;
}
.medium_color {
    background-color: #f1734f !important;
}
.high_color {
    background-color: red !important;
}
.label {
    font-weight: bold;
}

.selected_style_details_modal  {
    border: 2px black;
    color: #0275d8;
    font-weight: bold;
}

.blockStyles {
    font-weight: bold;
    background-color: #EBEDF0;
    border: 2px solid #dadee0;
    padding: 3px;
    margin: 3px;
    margin-bottom: 5px;
    border-radius: 15px;
}

#movementHistoryList.verti-timeline .event-list {
    padding: 0px 0px 10px 30px;
}

#kanbanModal .modal-md {
  max-width: 580px !important;
}


</style>