import { render, staticRenderFns } from "./action-plans.vue?vue&type=template&id=7452a7a6&scoped=true"
import script from "./action-plans.vue?vue&type=script&lang=js"
export * from "./action-plans.vue?vue&type=script&lang=js"
import style0 from "./action-plans.vue?vue&type=style&index=0&id=7452a7a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7452a7a6",
  null
  
)

export default component.exports